export const getPhoto = (userLogin) => {
    if (userLogin !== null) {
        const { photo } = userLogin;
        const msiAccessToken = localStorage.getItem('msiAccessToken');
        const myRequest = new Request(photo,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'image/jpeg',
                    Authorization: `Bearer ${msiAccessToken}`
                })
            });
        return fetch(myRequest).then(res => {
            switch (res.status) {
                case 400:
                    return null;
                case 200:
                    return res.blob();
                default:
                    break;
            }
        });
    }
};

export const getMessages = () => {
    const url = 'https://graph.microsoft.com/v1.0/me/messages';
    const msiAccessToken = localStorage.getItem('msiAccessToken');
    const myRequest = new Request(url,
        {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${msiAccessToken}`
            })
        });
    return fetch(myRequest).then(res => {
        switch (res.status) {
            case 400:
                return null;
            case 200:
                return res.json();
            default:
                break;
        }
    });
};

export const getCalendarToday = () => {
    const newDate = new Date();
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const startDate = `${year}-${month}-${day}T00:00:00.0000000`;
    const endDate = `${year}-${month}-${day}T23:59:59.0000000`;
    // eslint-disable-next-line
    const url = `https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=${startDate}&enddatetime=${endDate}&$orderby=start/dateTime`;

    const msiAccessToken = localStorage.getItem('msiAccessToken');

    const myRequest = new Request(url,
        {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${msiAccessToken}`
            })
        });
    return fetch(myRequest).then(res => {
        switch (res.status) {
            case 400:
                return null;
            case 200:
                return res.json();
            default:
                break;
        }
    });
};

export const getRecentFiles = (limit = 5) => {
    const url = `https://graph.microsoft.com/v1.0/me/drive/recent?$top=${limit}`;

    const msiAccessToken = localStorage.getItem('msiAccessToken');

    const myRequest = new Request(url,
        {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${msiAccessToken}`
            })
        });
    return fetch(myRequest).then(res => {
        switch (res.status) {
            case 400:
                return null;
            case 200:
                return res.json();
            default:
                break;
        }
    });
};
