import Loadable from 'react-loadable';
import Loading from '@korian/socle-back-office/dist/components/Loading';

// export const DashboardPage = Loadable({
//   loader: function loader() {
//     return import('../Pages/Dashboard');
//   },
//   loading: Loading,
// });
// #################### SOCLE ENTITIES ############################

export const LogErrorList = Loadable({
    loader: function loader() {
        return import('../Pages/Entities/LogError/LogErrorList');
    },
    loading: Loading
});
export const RequestList = Loadable({
    loader: function loader() {
        return import('../Pages/Entities/Request/RequestList');
    },
    loading: Loading
});
export const RestList = Loadable({
    loader: function loader() {
        return import('../Pages/Entities/Rest/RestList');
    },
    loading: Loading
});
export const LogHistory = Loadable({
    loader: function loader() {
        return import('../Pages/Entities/LogHistory/LogHistoryList');
    },
    loading: Loading
});
export const LogManageList = Loadable({
    loader: function loader() {
        return import('../Pages/Entities/ManageLogs/LogManageList');
    },
    loading: Loading
});
export const UsersList = Loadable({
    loader: function loader() {
        return import('../Pages/Entities/Users/UsersList');
    },
    loading: Loading
}); // ###################### CONNEXION ###########################

export const Login = Loadable({
    loader: function loader() {
        return import('../Pages/Users/Login');
    },
    loading: Loading
});
export const LoginDedicated = Loadable({
    loader: function loader() {
        return import(
            '@korian/socle-back-office/dist/containers/Pages/Standalone/LoginDedicated'
        );
    },
    loading: Loading
}); // #################### ERROR VIEWS #############################

export const NotFound = Loadable({
    loader: function loader() {
        return import('../NotFound/NotFound');
    },
    loading: Loading
});
export const NotFoundDedicated = Loadable({
    loader: function loader() {
        return import(
            '@korian/socle-back-office/dist/containers/Pages/Standalone/NotFoundDedicated'
        );
    },
    loading: Loading
});
export const Error = Loadable({
    loader: function loader() {
        return import('@korian/socle-back-office/dist/containers/Pages/Error');
    },
    loading: Loading
});
