import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import dataMenu from './menu';

export default function AuthenticatedRoute({
    component: ComponentPassed,
    isAuthenticated,
    ...rest
}) {
    const { path, roles } = rest;
    let result = 0;
    // Vérifions si l'utilisateur connecté à les droits pour accéder à cette url
    dataMenu.forEach((item) => {
        if (item.granted.length > 0 && item.link === path) {
            roles.forEach((e) => {
                if (item.granted.includes(e)) {
                    // Incrémenté uniquement lorsque l'utilisateur est autorisé
                    result++;
                }
            });
        }
    });

    // Condition 1 : User authentifié et possède les droits d'accès à l'url
    if (isAuthenticated && result > 0) {
        return (
            <Route
              {...rest}
              render={(props) => <ComponentPassed {...props} {...isAuthenticated} />}
            />
        );
    }
    // Condition 2 : User authentifié et ne possède les droits d'accès à l'url
    if (isAuthenticated && result === 0) {
        return (
            <Route {...rest} render={() => <Redirect to="/app/forbidden" />} />
        );
    }
    return <Route {...rest} render={() => <Redirect to="/login" />} />;
}
