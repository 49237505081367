// const PermDataSettingIcon = require('@material-ui/icons/PermDataSetting');
// const EventIcon = require('@material-ui/icons/Event');
// const ViewAgendaIcon = require('@material-ui/icons/ViewAgenda');
// const DomainIcon = require('@material-ui/icons/Domain');
// const LocationCityIcon = require('@material-ui/icons/LocationCity');
// const CategoryIcon = require('@material-ui/icons/Category');
// const AttachFileIcon = require('@material-ui/icons/AttachFile');

// const { MdApps } = require('react-icons/md');
// const { MdAttachFile } = require('react-icons/md');
// const { MdViewAgenda } = require('react-icons/md');
// const { MdEvent } = require('react-icons/md');
// const { MdWidgets } = require('react-icons/md');
// const { MdPermDataSetting } = require('react-icons/md');
// const { MdLocationCity } = require('react-icons/md');
// const { MdDomain } = require('react-icons/md');
const { IoIosConstruct } = require('react-icons/io');
const { IoIosPaper } = require('react-icons/io');
const { IoMdPaper } = require('react-icons/io');
const { IoMdSend } = require('react-icons/io');
const { BiError } = require('react-icons/bi');
// const { IoMdGlobe } = require('react-icons/io');
const { IoIosContacts } = require('react-icons/io');
// const { IoIosSettings } = require('react-icons/io');
// const { MdPlace } = require('react-icons/md');
// const { FaMapSigns } = require('react-icons/fa');
// const { FaSearchLocation } = require('react-icons/fa');
// const { MdSwapCalls } = require('react-icons/md');
// const { FaExchangeAlt } = require('react-icons/fa');

module.exports = [
    {
        key: 'requests',
        name: 'Liste des DA',
        link: '/app/requests',
        icon: {
            value: IoMdPaper
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        key: 'rests',
        name: 'Liste des req. REST',
        link: '/app/rests',
        icon: {
            value: IoMdSend
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        key: 'log_errors',
        name: 'Log Erreurs',
        link: '/app/log_errors',
        icon: {
            value: BiError
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        key: 'gestionnaire_logs',
        name: 'Gestionnaire des logs',
        link: '/app/logs',
        icon: {
            value: IoIosConstruct
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        key: 'historique_logs',
        name: 'Historique des logs',
        link: '/app/historique/logs',
        icon: {
            value: IoIosPaper
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        key: 'users',
        name: 'Utilisateurs',
        link: '/app/utilisateurs',
        icon: {
            value: IoIosContacts
        },
        granted: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    }
];
