import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Outer from '@korian/socle-back-office/dist/containers/Templates/Outer';
import { Login, NotFound } from './pageListAsync';

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { changeMode, history } = this.props;
        return (
            <Outer>
                <Switch>
                    <Route
                      path="/login"
                      render={(props) => (
                          <Login {...props} history={history} changeMode={changeMode} />
                      )}
                    />
                    <Route component={NotFound} />
                </Switch>
            </Outer>
        );
    }
}

export default Auth;
