/* eslint-disable no-undef */
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: '3c3650f1-5024-45f6-a2df-0911d5955be8',
        redirectUri: ApiConfig.logoutRedirectAzureUri,
        postLogoutRedirectUri: ApiConfig.logoutRedirectAzureUri
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        'https://graph.microsoft.com/OpenId',
        'https://graph.microsoft.com/User.Read',
        'https://graph.microsoft.com/User.ReadBasic.All'
    ]
};

// Options
const options = {
    loginType: LoginType.Popup
};

const authProvider = new MsalAuthProvider(
    config,
    authenticationParameters,
    options,
);

export default authProvider;
