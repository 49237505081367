import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginDedicated from '@korian/socle-back-office/dist/containers/Pages/Standalone/LoginDedicated';
import ThemeWrapper, {
    AppContext
} from '@korian/socle-back-office/dist/containers/App/ThemeWrapper';
import Auth from './Layout/Auth';
import Application from './Layout/Application';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
const dotenv = require('dotenv');

dotenv.config();

function App() {
    return (
        <ThemeWrapper>
            <AppContext.Consumer>
                {(changeMode) => (
                    <Switch>
                        <Route path="/" exact component={LoginDedicated} />
                        <Route
                          path="/app"
                          render={(props) => (
                              <Application {...props} changeMode={changeMode} />
                          )}
                        />
                        <Route
                          render={(props) => <Auth {...props} changeMode={changeMode} />}
                        />
                    </Switch>
                )}
            </AppContext.Consumer>
        </ThemeWrapper>
    );
}

export default App;
